// @import "common/src/assets/styles/color";
// @import "@timhortons/common/src/assets/styles/color";
$font-family: "Sofia-Pro-Regular";

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
}
.header {
  font-size: 20px;
  font-weight: 600;
  font-family: $font-family;
}
.name-continer {
  color: grey;
  padding: 20px;
}
.message-container {
  white-space: pre-wrap;
  color: black;
}
.stack-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  outline-style: none;
  padding: 20px;
}
