// @import "src/assets/styles/color";
$fontFamily: "Sofia-Pro-Regular";
$white: #ddd9da;
$white1: #ffffff;
$red1: #ad0024;
$red2: #faf2f4;
$grey: #c0b9ba;
$black: #000000;
$black1: #594547;
$black2: #30170a;

.content {
  color: purple;
}
.react-calendar__navigation {
  margin: 10px;
}
.react-calendar.react-calendar--doubleView.react-double-calendar {
  width: 700px;
  background: white;
  border: 1px solid $white;
  border-radius: 4px;
  font-family: $fontFamily;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  font-family: $fontFamily;
}
.react-calendar button {
  margin: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar.react-calendar--doubleView.react-double-calendar
  .react-calendar__tile.react-calendar__month-view__days__day {
  // width: 32px;
  // height: 32px;
  width: 27px;
  height: 27px;
  padding: 6px;
  border-radius: 0%;
  // border-radius: 0% !important;
  margin-right: 8px;
  margin-bottom: 6px;
  flex-basis: 10.127% !important;
  border: none;
  background-color: $white1;
}
.react-calendar.react-calendar--doubleView.react-double-calendar
  .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--active {
  // border: 1px solid $red1;
  background: $red2 !important;
  border: none;
  color: $black1;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  font-family: $fontFamily;
}
.react-calendar.react-calendar--doubleView.react-double-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
  border: 1px solid $red1;
  border-radius: 4px;
  // border-radius: 4px !important;
  color: $red1 !important;
  font-weight: 600 !important;
}
.react-calendar.react-calendar--doubleView.react-double-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
  border: 1px solid $red1;
  border-radius: 6px;
  // border-radius: 6px !important;
  font-weight: 600 !important;
  color: $red1 !important;
}
.react-calendar.react-calendar--doubleView.react-double-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth {
  border: none;
  color: $grey !important;
}
.react-calendar.react-calendar--doubleView.react-double-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  border: none;
  color: $grey !important;
}

abbr[title] {
  text-decoration: none;
}
.react-calendar.react-calendar--doubleView.react-double-calendar
  .react-calendar__month-view__days__day--neighboringMonth {
  color: $grey !important;
}
.react-calendar__month-view__weekdays__weekday {
  margin-right: 6px;
  padding: 0px;
  // padding: 0px !important;
  overflow: visible !important;
  flex-basis: 11.8% !important;
  padding-bottom: 12px;
  text-decoration: none;
  // width: 32px;
  // height: 32px;
  width: 27px;
  height: 22px;
  max-width: 32px !important;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  &:last-child {
    margin-right: 0px;
    padding-right: 0px;
  }
}
.react-calendar__month-view__weekdays {
  justify-content: center;
}
.react-calendar__month-view__days {
  justify-content: center;
}

/* NAVIGATION BLOCK */
.react-calendar__navigation__label {
  font-size: 14px;
  font-weight: 600;
  font-family: $fontFamily;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  // background: yellow;
  font-style: normal;
  color: $black2;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--to {
  // background: yellow;
  font-style: normal;
  color: $black2;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

// .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--active{
//   border: none;
// }

.react-calendar__navigation__label__divider {
  color: white;
}
.react-calendar__navigation button {
  background: none;
  font-family: $fontFamily;
  border: 0;
  align-items: center;
}
.react-calendar__navigation__arrow {
  font-size: 18px;
  font-weight: 900;
}
.react-calendar__navigation__prev-button {
  width: 40px;
}
.react-calendar__navigation__next-button {
  width: 40px;
}
/* MONTH BLOCK */
.react-calendar__year-view__months {
  justify-content: center;
}
.react-calendar__year-view__months__month {
  flex-basis: 33% !important;
}
.react-calendar__decade-view__years__year {
  flex-basis: 25% !important;
}
.react-calendar__century-view__decades__decade {
  flex-basis: 33% !important;
}
.react-calendar__tile.react-calendar__year-view__months__month {
  margin-bottom: 10px;
  padding: 5px;
  border: none;
  background-color: $white1;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  background: $red2;
  color: $red1;
  border-radius: 6px;
  font-weight: 600;
  font-family: $fontFamily;
}
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  background: $red2;
  color: $red1;
  border-radius: 6px;
  font-weight: 600;
  font-family: $fontFamily;
}
/* YEAR BLOCK */
.react-calendar__decade-view {
  justify-content: center;
}
.react-calendar__tile.react-calendar__decade-view__years__year {
  border: none;
  background-color: $white1;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
  background: $red2;
  color: $red1;
  border-radius: 6px;
  font-weight: 600;
  font-family: $fontFamily;
}
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
  background: $red2;
  color: $red1;
  border-radius: 6px;
  font-weight: 600;
  font-family: $fontFamily;
}
/* CENTURY BLOCK */
.react-calendar__century-view {
  justify-content: center;
}
.react-calendar__tile.react-calendar__century-view__decades__decade {
  border: none;
  background-color: $white1;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
  background: $red2;
  color: $red1;
  border-radius: 6px;
  font-weight: 600;
  font-family: $fontFamily;
}
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
  background: $red2;
  color: $red1;
  border-radius: 6px;
  font-weight: 600;
  font-family: $fontFamily;
}
// Double View CSS add
.react-calendar__month-view__weekNumbers {
  color: white;
}
.react-calendar--doubleView {
  // width: 700px;
  // height: 450px;
  width: 698px;
  height: 360px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 100%;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: $red2;
}
